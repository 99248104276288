import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
import(/* webpackMode: "eager" */ "/app/node_modules/react-responsive-carousel/lib/styles/carousel.min.css");
import(/* webpackMode: "eager" */ "/app/src/assets/icons/arrow/arrow-fill-blue-icon.svg");
import(/* webpackMode: "eager" */ "/app/src/assets/images/home/gobuid-banner.png");
import(/* webpackMode: "eager" */ "/app/src/components/homePage/AnimationText.jsx");
import(/* webpackMode: "eager" */ "/app/src/components/homePage/FeaturesDeskDisplay.jsx");
import(/* webpackMode: "eager" */ "/app/src/components/homePage/FeaturesMobCarousel.jsx");
import(/* webpackMode: "eager" */ "/app/src/components/homePage/QASection.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/homePage/RoleBasedSolution.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/homePage/VideoPopUp.jsx");
import(/* webpackMode: "eager" */ "/app/src/components/UI/Button/ButtonStartFree.jsx")